<template>
  <div>
    <SEARCH :searchChange='onCallbackSearch' :list="searchArr"></SEARCH>
    <TABS :list="tabsArr"></TABS>
    <TABLE :buttonChange='onCallbackButton' :isShowSele="false" :list="tableArr" :data="dataArr"></TABLE>
    <PAGE :pageChange='onCallbackPage' :limitChange='onCallbackLimit' :count="count"></PAGE>

  </div>
</template>

<script>
import SEARCH from '@/components/search' // 顶部搜索
import TABS from '@/components/tabs' // 横向 button
import TABLE from '@/components/table' // 列表
import PAGE from '@/components/page' // 分页
import { getDeep, delDeep } from '@/api/project'
export default {
  name: 'DeepManage',
  components: {
    SEARCH,
    TABS,
    TABLE,
    PAGE,
  },
  data() {
    return {
      searchArr: [
        {
          name: '深基坑名称',
          type: 'input',
          options: [],
          key: 'deepName',
        },
        {
          name: '施工进度',
          type: 'picker',
          options: [],
          key: 'conProgress',
        },
        {
          name: '所属工程',
          type: 'picker',
          options: [],
          key: 'fenceProject',
        },
      ], // 搜索列表
      tabsArr: [
        {
          type: 'primary',
          name: '新增深基坑',
          func: '',
        },
      ], // 标签列表
      tableArr: [
        {
          prop: 'name',
          label: '深基坑名称',
          width: '150',
        },
        {
          prop: 'p_id',
          label: '所属工程',
          width: '150',
        },
        {
          prop: 'username',
          label: '负责人',
          width: '150',
        },
        {
          prop: 'location',
          label: '地理位置',
          width: '150',
        },
        {
          prop: 'current_depth',
          label: '当前深度(m)',
          width: '150',
        },
        {
          prop: 'complete_depth',
          label: '计划完成深度(m)',
          width: '150',
        },
        {
          prop: 'status2',
          label: '施工进度',
          width: '150',
        },
        {
          prop: 'create_at',
          label: '相关数据',
          width: '150',
          type: 'other',
        },
        {
          prop: 'create_at',
          label: '创建时间',
          width: '150',
        },
        {
          prop: '',
          label: '操作',
          width: '',
          type: 'but',
        },
      ], // 表头列表
      dataArr: [], // 数据列表
      butnArr: [
        {
          type: '',
          name: '编辑',
          func: '',
        },
        {
          type: 'danger',
          name: '删除',
          func: 'removeDeep',
        },
      ], // 数据-操作列表
      count: 0, // 总页数
      formData: {
        page: 1,
        limit: 10,
        name: '',
      },
    }
  },
  created() {},
  mounted() {
    this._loadData()
  },
  methods: {
    _loadData: function () {
      this.formData.unit_id = this.$store.getters.USER_ID
      getDeep(this.formData).then((res) => {
        const { result } = res

        this.count = result.count
        result.data.forEach(item => {
          item.buttons = this.butnArr
          item.other = [{ type: '', name: '相关数据', func: '' }]
        })
        this.dataArr = result.data
      })
    },

    // 删除坑
    removeDeep: function({ id }) {
      delDeep(id).then(res => {
        this.$toast(res.message)
        this._loadData()
      })
    },

    // 收到搜索回调
    onCallbackSearch: function(data) {
      // fenceProject conProgress
      const { deepName } = data
      this.formData.name = deepName
      this._loadData()
    },

    // 收到 table内方法 回调
    onCallbackButton: function(item, func) {
      this[func](item)
    },

    // 收到改变页码回调
    onCallbackPage: function(page) {
      this.formData.page = page
      this._loadData()
    },
    // 收到改变一页数量回调
    onCallbackLimit: function(limit) {
      this.formData.limit = limit
      this._loadData()
    },
  },
}
</script>

<style lang="stylus" scoped></style>
